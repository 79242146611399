import * as React from 'react';
import styled from 'styled-components';
import uuid from 'react-uuid';

const StyledSolarLegend = styled.div`
	color: ${({ theme }) => theme.colors.gold};
	display: none;
	font-family: ${({ theme }) => theme.fontFamily.secondary};
	font-size: 14px;
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	line-height: 20px;
	margin-bottom: 102px;
	margin-top: 30px;
	padding: 0 34px;
	width: 100%;
	@media (max-width: 767px) {
		text-align: center;
		display: grid;
		grid-template-columns: 1.5fr 1.5fr 1fr;
		row-gap: 1rem;
		margin-bottom: 30px;
	}
	@media (min-width: 768px) and (max-width: 1365px) {
		text-align: center;
		display: grid;
		grid-template-columns: 1.5fr 1fr 1.5fr 1fr 1fr;
		margin-bottom: 50px;
		padding: 0 64px;
		row-gap: 15px;
	}
`;

const StyledLegendNumber = styled.div`
	border: solid 1px ${({ theme }) => theme.colors.gold};
	border-radius: 50%;
	font-size: 12px;
	height: 18px;
	margin-bottom: 3px;
	position: relative;
	width: 18px;
	p {
		left: 50%;
		margin-top: 1px;
		position: absolute;
		text-align: center;
		top: 50%;
		transform: translate(-50%, -50%);
	}
`;

const StyledSolarLegendItem = styled.div`
	color: ${({ theme }) => theme.colors.gold};
	font-family: ${({ theme }) => theme.fontFamily.secondary};
	font-size: 14px;
	line-height: 20px;
	text-align: left;
	width: 100%;
`;
const StyledSolarLegendText = styled.p`
	&:nth-of-type(2n) {
		color: ${({ theme }) => theme.colors.white};
	}
`;

const SolarSystemLegend = ({ legend }) => (
	<StyledSolarLegend>
		{legend.map((item) => (
			<StyledSolarLegendItem key={uuid()}>
				<StyledLegendNumber>
					<p>{item.number}</p>
				</StyledLegendNumber>
				<StyledSolarLegendText>{item.name}</StyledSolarLegendText>
				<StyledSolarLegendText>{item.distance}</StyledSolarLegendText>
			</StyledSolarLegendItem>
		))}
	</StyledSolarLegend>
);

export default SolarSystemLegend;
