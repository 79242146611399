import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { useDispatch, useSelector } from 'react-redux';
import ReactFullpage from '@fullpage/react-fullpage';
import { isMobile } from 'react-device-detect';
import { setIsDropdownMenu } from '../../state/isDropdownMenuSlice';
import gotoAnimate from '../../animations/animationsGsap';
import SolarSystemLegend from './SolarSystem/SolarSystemLegend';
import { setScaleIntroAnimation } from '../../state/scaleIntroAnim';
import ScrollIndicator from '../ScrollIndicator/ScrollIndicator';
import { setIndicatorAnim } from '../../state/indicatorAnim';
import { setIntroStartModule } from '../../state/introStartModule';
import { setIntroLoadedOnce } from '../../state/introLoadedOnce';
import { setMenuColor } from '../../state/menuColor';
import SolarSystemV3 from './SolarSystem/SolarSystemV3';
import MainPhoto from '../../assets/image/investment/investment1.webp';
import BigPhoto from '../../assets/image/investment/dolnych_zewnatrz.webp';
import SmallImagesOne from '../../assets/image/investment/investment2.webp';
import SmallImagesTwo from '../../assets/image/investment/investment3.webp';
import Sketch from '../../assets/image/investment/sketchInvest.webp';

const StyledView = styled.section`
	position: relative;
	width: 100%;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 40px 140px;
  	height: 100vh;
  	&.section, &.fp-scrollable, &.fp-section{
		height: 100vh !important;
  	}
	@media (min-width: 769px) and (max-width: 1024px) {
		padding: 32px 64px;
	}
	@media (max-width: 768px) {
		padding: 0 24px;
	}
  	@media (max-width: 1366px){
      &.section, &.fp-scrollable, &.fp-section{
		&:nth-of-type(1){
		   top: -50px;
		}
        &:nth-of-type(3){
          justify-content: start;
          height: 60vh !important;
        }
      }
	}
	&.description {
      background-color: ${({ theme }) => theme.colors.creamyLight};
	  position: relative;
		@media (max-width: 768px) {
			align-items: flex-start;
          	height: 75vh;
		}
	}
	&.main-photo {
      position: relative;
	  align-items: start;
      .reveal {
		top: 0;
		position: absolute;
        width: 101%;
        height: 100%;
		left: -2px;
        background-color: var(--creamy-light);
		overflow: hidden;
      }
	  @media (max-width: 1365px){
		height: auto;
	  }
	}
	&.photos {
		flex-direction: row;
	  	justify-content: start;
      	column-gap: 120px;
	  	align-items: baseline;
	    overflow: hidden;
		@media (max-width: 768px) {
			flex-direction: column;
		  	margin-bottom: 24px;
		  	row-gap: 24px;
		}
      @media (max-width: 1365px){
        height: auto;
		column-gap: 15px;
      }
	  @media (min-width: 1366px){
        padding: 59px 140px 40px;
	  }
      .cover {
        top: 0;
        position: absolute;
        width: 105%;
        z-index: 100;
		left: -6px;
        background-color: var(--creamy-light);
        height: 105%;
      }
	  div{
		img{
          aspect-ratio: 2/1;
		}
	  }
	}
  	&.main-photo, &.photos{
      font-family: ${({ theme }) => theme.fontFamily.secondary};
      height: 100%;
      width: 100%;
      div{
        height: 100%;
        width: 100%;
        img{
          width: 100%;
          height: 100%;
        }
      }
	  p{
		padding-top: 24px;
	  }
	  &.last-section {
        background-color: ${({ theme }) => theme.colors.creamyLight};
		padding: 140px;
	  }
      @media (max-width: 1023px) {
        p{
		  padding-top: 0;
		}
        &.last-section {
          padding: 24px;
        }
      }
	}
	  &.solar-system {
		background-color: ${({ theme }) => theme.colors.darkGreen};
		padding: 0;
		height: 0;
		min-height: 100vh;
		svg {
		  width: 1000px;
		  @media (max-width: 767px) {
			width: 100%;
		  }
		  @media (min-width: 768px) and (max-width: 1023px) {
			width: 100%;
			margin-top: 50px;
		  }
		  g {
			#description{
			  opacity: 0;
			  transition: opacity 0.3s ease-in-out; !important;
			  font-family: ${({ theme }) => theme.fontFamily.secondary};
			  font-weight: ${({ theme }) => theme.fontWeight.regular};
			  rect{
				height: 50px;
				width: 230px;
			  }
			}
		  }
		}
	  }
`;

const StyledFullscreenBlend = styled.div`
	display: flex;
	height: calc(var(--vh, 1vh) * 100);
	left: 0;
	position: absolute;
	top: 0;
	width: 100vw;
	z-index: 10;
	@media (max-width: 1366px) {
		height: calc(var(--vh, 1vh) * 100 + 50px);
		//max-height: calc(100vh - 50px);
	}
`;

const StyledRectangle = styled.div`
	bottom: 0;
	height: 100px;
	left: 50%;
	position: absolute;
	transform: translate(-50%);
	width: 100px;
	//@media (max-width: 385px) {
	//	bottom: -25px;
	//}
`;

const StyledImgWrapper = styled.div`
	align-items: flex-end;
	overflow: hidden;
	position: relative;
	@media (max-width: 769px) {
		height: 100%;
		margin-bottom: 24px;
	}
	&.photo-small {
		height: 100%;
		margin-bottom: 0;
		@media (max-width: 769px) {
			height: 100%;
		}
	}
`;
const StyledBackground = styled.div`
	background-image: url(${({ img }) => img});
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	height: 100%;
	left: 50%;
	opacity: 0.3;
	position: absolute;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	width: 100%;
	@media (max-width: 420px) {
		opacity: 0.65;
	}
`;
const StyledDescription = styled.span`
	font-family: ${({ theme }) => theme.fontFamily.secondary};
	font-size: 18px;
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	line-height: 24px;
	max-width: 820px;
	opacity: 0;
	overflow: hidden;
	position: relative;
	text-align: left;
	&.text-desc-5 {
		margin-bottom: 40px;
		max-width: 980px;
		text-align: center;
		top: 50px;
		@media (max-width: 380px) {
			margin-bottom: 20px;
		}
	}
	&.text-desc-2-5 {
		max-width: 980px;
		text-align: center;
		top: 100px;
	}
	&.text-desc-6 {
		margin-bottom: 40px;
		max-width: 980px;
		text-align: center;
		top: 50px;
	}
	&.text-desc-2-6 {
		max-width: 980px;
		text-align: center;
		top: 100px;
	}
	&.text-description-2-2 {
		font-size: 16px;
		text-align: center;
		top: 100px;
	}
	@media (max-width: 768px) {
		&.text-description-2-2 {
			text-align: left;
		}
	}
	@media (max-width: 1024px) {
		font-size: 14px;
		line-height: 20px;
		max-width: 100%;
	}
	@media (min-width: 1660px) {
		max-width: 78%;
	}
`;

const StyledDescriptionHeader = styled.span`
	font-family: ${({ theme }) => theme.fontFamily.primary};
	font-size: 150px;
	font-weight: ${({ theme }) => theme.fontWeight.light};
	line-height: 114px;
	margin-bottom: 73px;
	opacity: 0;
	position: relative;
	top: 100px;

	@media (min-width: 1025px) and (max-width: 1366px) {
		font-size: 120px;
		line-height: 125px;
	}
	@media (min-width: 768px) and (max-width: 1024px) {
		font-size: 100px;
		line-height: 105px;
	}
	@media (max-width: 767px) {
		margin-bottom: 15px;
		font-size: 36px;
		line-height: 45px;
	}
`;

const StyledText = styled.span`
	font-family: ${({ theme }) => theme.fontFamily.primary};
	font-size: 100px;
	font-weight: ${({ theme }) => theme.fontWeight.light};
	line-height: 120px;
	max-width: 798px;
	opacity: 0;
	position: relative;
	top: 100px;

	&.second,
	&.third {
		font-size: 64px;
		line-height: 72px;
	}
	&.third {
		max-width: 845px;
	}
	@media (min-width: 768px) and (max-width: 1024px) {
		font-size: 80px;
		line-height: 95px;
		max-width: 100%;
	}
	@media (max-width: 767px) {
		font-size: 44px;
		line-height: 64px;
		max-width: 100%;
		&.second,
		&.third {
			font-size: 36px;
			line-height: 45px;
		}
	}
`;

const StyledSolarInfo = styled.p`
	bottom: 40px;
	color: ${({ theme }) => theme.colors.gold};
	font-family: ${({ theme }) => theme.fontFamily.secondary};
	font-size: 14px;
	left: 40px;
	line-height: 20px;
	max-height: 300px;
	max-width: 364px;
	position: absolute;
	text-align: left;
	@media (max-width: 767px) {
		position: relative;
		top: 0;
		left: 0;
		margin: 30px 15px 15px;
		text-align: center;
	}
	@media (min-width: 768px) and (max-width: 1365px) {
		top: 58px;
		left: 64px;
		max-width: 214px;
	}
`;

const Investments = ({ data, pageContext }) => {
	const {
		textHeader,
		textParagraph,
		textParagraphDescription,
		textParagraphIi,
		textTitleDescription,
		foundersDesc,
		foundersDescIi,
		foundersHeader,
		foundersPerson,
		solarSystemMainInfo,
		solarSystemLegendItem,
	} = data;

	const global = pageContext?.globals?.[0] || [];
	const textIndicator = global?.textIndicator || '';
	const [widthWindow, setWidthWindow] = useState(0);
	const [scrollFlag, setScrollFlag] = useState(false);
	const [fullPageContainer, setFullPageContainer] = useState(null);
	const [menuContainer, setMenuContainer] = useState(null);
	const trigger = useRef(null);
	const dispatch = useDispatch();
	const offset = trigger?.current?.offsetTop;
	const isDropdownMenuOpen = useSelector((state) => state.isDropdownMenuOpen.isDropdownMenuOpen);

	const cleanFullPageStyles = () => {
		document.documentElement.style.scrollBehavior = null;
		document.documentElement.style.overflow = null;
		document.documentElement.style.height = null;
		document.querySelector('body').classList.remove('fp-scrollable');
		document.querySelector('body').style.position = null;
		document.querySelector('body').style.scrollBehavior = null;
		document.querySelector('body').style.overflow = null;
		document.querySelector('body').style.height = null;
		document.getElementById('gatsby-focus-wrapper').style.height = null;
		document.getElementById('gatsby-focus-wrapper').style.position = null;
	};

	useEffect(() => {
		if (typeof document !== undefined) {
			setFullPageContainer(document.getElementById('fullpage'));
			setMenuContainer(document.getElementById('main-menu'));
			document.getElementById('main-menu').style.transitionDuration = null;
			document.getElementById('main-menu').style.transition = null;
		}

		dispatch(setScaleIntroAnimation(true));
		dispatch(setIntroStartModule(false));
		dispatch(setMenuColor(true));
		dispatch(setIntroLoadedOnce(true));
		if (typeof window !== 'undefined') {
			const width = window?.innerWidth || 0;
			setWidthWindow(width);
			if (width < 1024) {
				dispatch(setIsDropdownMenu(true));
			}
		}

		isMobile &&
			(window.fullpage_api.setAutoScrolling(false),
			document.getElementById('fullpage').classList.add('fp-destroyed'),
			document.documentElement.classList.remove('fp-enabled'));

		return () => {
			cleanFullPageStyles();
			document.querySelector('html').style.transitionDuration = '0s';
			document.querySelector('html').style.backgroundColor = 'var(--background-color)';
			document.getElementById('main-menu').style.transitionDuration = 'top 0s';
			document.getElementById('main-menu').style.transition = '0s';
			document.getElementById('main-menu').classList.remove('hide');
		};
	}, []);

	useEffect(() => {
		if (isDropdownMenuOpen) {
			window.fullpage_api.setAllowScrolling(false);
		} else {
			if (window.fullpage_api.getScrollY() === 0) {
				// dispatch(setIsDropdownMenu(false));
			}
			window.fullpage_api.setAllowScrolling(true);
		}
	}, [isDropdownMenuOpen]);

	const toggleAutoScroll = (bool) => {
		if (bool) {
			fullPageContainer.classList.remove('fp-destroyed');
			dispatch(setIsDropdownMenu(true));
			!scrollFlag && (window.fullpage_api.setAutoScrolling(bool), window.fullpage_api.moveSectionUp(), setScrollFlag(true));
		} else {
			fullPageContainer?.classList.add('fp-destroyed');
			window.fullpage_api.setAutoScrolling(bool);
		}
	};
	const handleScroll = () => {
		if (offset - window.scrollY > 1) {
			!isMobile && toggleAutoScroll(true);
		}
	};
	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	});
	useEffect(() => {
		if (typeof window !== 'undefined') {
			const vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		}
	}, []);
	return (
		<ReactFullpage
			licenseKey={process.env.GATSBY_FULLPAGE_LICENSE_KEY}
			scrollOverflow={false}
			fitToSection={false}
			keyboardScrolling={false}
			onLeave={(origin, destination) => {
				if (destination.index === 0) {
					gotoAnimate(destination.index, 'html-bg-color', 'var(--background-color)');
					if (widthWindow > 1366) {
						!isDropdownMenuOpen && dispatch(setIsDropdownMenu(false));
						menuContainer.classList.remove('hide');
					}
				} else if (destination.index === 1) {
					dispatch(setIndicatorAnim(true));
					gotoAnimate(destination.index, 'text', destination.item.firstChild);
					gotoAnimate(destination.index, 'html-bg-color', 'var(--creamy)');
					dispatch(setIsDropdownMenu(true));
					widthWindow > 1366 && menuContainer?.classList.add('hide');
				} else if (destination.index === 2) {
					gotoAnimate(destination.index, 'text', destination.item.firstChild);
					gotoAnimate(destination.index, 'html-bg-color', 'var(--creamy-light)');
				}
			}}
			afterLoad={(origin, destination) => {
				if (destination.isFirst) {
					gotoAnimate(destination.index, 'text', destination.item.firstChild);
				}
				if (destination.isLast) {
					!isMobile && toggleAutoScroll(false);
					setScrollFlag(false);
				}
			}}
			render={() => (
				<>
					<StyledView className="section active main">
						<StyledText data-animation-type-section="text">{textHeader}</StyledText>
						<StyledFullscreenBlend>
							<StyledRectangle id="indicatorBtn">
								<ScrollIndicator text={textIndicator} fixed={false} pageContext={pageContext} />
							</StyledRectangle>
						</StyledFullscreenBlend>
					</StyledView>
					<StyledView className="section second" id="scrollTo">
						<StyledText className="second" data-animation-type-section="text">
							{textParagraph}
						</StyledText>
					</StyledView>
					<StyledView className="section third" ref={trigger}>
						<StyledText className="third" data-animation-type-section="text">
							{textParagraphIi}
						</StyledText>
					</StyledView>
					<StyledView className="main-photo">
						<StyledImgWrapper className="main-photo anim" data-animation-type="one-image">
							<img src={MainPhoto} alt="investments" />
							<div className="reveal" />
						</StyledImgWrapper>
					</StyledView>
					<StyledView className="photos anim" data-animation-type="photos">
						<StyledImgWrapper className="photo-small">
							<div className="cover" />
							<img src={SmallImagesOne} alt="investments" />
						</StyledImgWrapper>
						<StyledImgWrapper className="photo-small">
							<div className="cover" />
							<img src={SmallImagesTwo} alt="investments" />
						</StyledImgWrapper>
					</StyledView>
					<StyledView className="description anim" data-animation-type="header-desc">
						<StyledBackground img={Sketch} />
						<StyledDescriptionHeader className="text-description-2">{textTitleDescription}</StyledDescriptionHeader>
						<StyledDescription className="text-description-2-2"> {textParagraphDescription}</StyledDescription>
					</StyledView>
					<StyledView className="solar-system anim ">
						<StyledSolarInfo>{solarSystemMainInfo}</StyledSolarInfo>
						<SolarSystemV3 legend={solarSystemLegendItem} />
						<SolarSystemLegend legend={solarSystemLegendItem} />
					</StyledView>
					<StyledView className="main-photo last-section">
						<StyledImgWrapper className="main-photo anim" data-animation-type="one-image">
							<img src={BigPhoto} alt="investments" />
							<div className="reveal" />
						</StyledImgWrapper>
						<p>Dolnych Młynów 10</p>
					</StyledView>
				</>
			)}
		/>
	);
};
export default Investments;
