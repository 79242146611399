import React from 'react';
import { graphql } from 'gatsby';
import Investments from '../components/investments/Investments';
import Seo from '../components/seo/Seo';

const InvestmentPage = ({ data: { investmentData, allFile }, pageContext }) => {
	const pageData = investmentData?.nodes?.[0] || '';
	const allInvestmentImages = allFile?.edges;
	const seoGlobal = pageContext?.seoGlobal?.data?.datoCmsSite?.globalSeo || [];
	const seoData = pageData?.seoSettings || '';

	return (
		<>
			<Seo seoGlobal={seoGlobal} seoData={seoData} />
			<Investments data={pageData} pageContext={pageContext} allInvestmentImages={allInvestmentImages} />
		</>
	);
};

export default InvestmentPage;

export const query = graphql`
	query ($locale: String) {
		investmentData: allDatoCmsInvestmentPage(filter: { locale: { eq: $locale } }) {
			nodes {
				slug
				seoSettings {
					title
					description
					twitterCard
					image {
						url
					}
				}
				textHeader
				textParagraph
				textParagraphDescription
				textParagraphIi
				textTitleDescription
				foundersDesc
				foundersDescIi
				foundersHeader
				foundersPerson {
					description
					name
				}
				solarSystemMainInfo
				solarSystemLegendItem {
					name
					number
					distance
				}
			}
		}
		allFile(filter: { sourceInstanceName: { eq: "investmentGallery" } }) {
			edges {
				node {
					id
					extension
					childImageSharp {
						gatsbyImageData
					}
				}
			}
		}
	}
`;
