import * as React from 'react';
import { useRef } from 'react';
import { isMobile } from 'react-device-detect';

const SolarSystemV3 = (props) => {
	const flyingRect = useRef(null);
	let timeout = null;

	const cursorOn = (item, id, x, y, text, distance) => {
		let rectWidth = 0;
		if (!isMobile) {
			clearTimeout(timeout);
			flyingRect.current.children[2].textContent = text;
			rectWidth = 80 + flyingRect.current.children[2].getBBox().width;
			flyingRect.current.children[0].style.height = '50px';
			flyingRect.current.children[0].style.width = rectWidth;
			flyingRect.current.children[1].textContent = distance;
			item.style.fill = '#af875c';
			x -= 14;
			y -= 8;

			// align rect behind current hover so its covering other svg objects
			document.querySelector('g').insertBefore(flyingRect.current, item.parentNode);

			// show rect, set position
			document.querySelector(`#${id}`).style.fill = 'black';
			flyingRect.current.setAttribute('transform', `translate(${x} ${y})`);
			flyingRect.current.style.opacity = 1;
			flyingRect.current.style.display = 'block';
		}
	};

	const cursorOff = (item, id) => {
		if (!isMobile) {
			// reset number color
			document.querySelector(`#${id}`).style.fill = '#af875c';

			// hide rect
			item.style.fill = '#1f302d';
			flyingRect.current.style.opacity = 0;
			// create timeout so animation has time to play
			timeout = setTimeout(() => {
				flyingRect.current.style.display = 'none';
			}, 300);
		}
	};

	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="-40 -70 800 800" {...props}>
			<g data-name="Group 325">
				<g data-name="Ellipse 20" transform="translate(243 209.504)" fill="none" stroke="#af875c">
					<ellipse cx={114.5} cy={114} rx={114.5} ry={114} stroke="none" />
					<ellipse cx={114.5} cy={114} rx={114} ry={113.5} />
				</g>
				<g data-name="Ellipse 25" transform="translate(287 253.504)" fill="none" stroke="#af875c">
					<ellipse cx={70.5} cy={70} rx={70.5} ry={70} stroke="none" />
					<ellipse cx={70.5} cy={70} rx={70} ry={69.5} />
				</g>
				<g id="rodzic" fill="#af875c" data-name="Layer 2">
					<g id="description" ref={flyingRect}>
						<rect fill="rgb(28, 28, 28)" />
						<text x="60" y="18" fill="white" fontSize={14} />
						<text x="60" y="41" fill="#af875c" fontSize={20} />
					</g>
					<path data-name="Rectangle 151" d="M348.022 350.817h19.816v.248h-19.816z" />
					<path
						data-name="Path 236"
						d="M334.418 324.759a4.435 4.435 0 0 1-4.766 4.805h-3.014v-9.378h3.091c2.874 0 4.689 1.65 4.689 4.573Zm-.743.077c0-2.655-1.66-4.444-4.07-4.444h-2.298v8.848a16.164 16.164 0 0 0 2.423.116c2.447 0 3.941-1.637 3.941-4.521Z"
					/>
					<path
						data-name="Path 237"
						d="M336.762 324.954a4.733 4.733 0 0 1 4.843-4.922 4.552 4.552 0 0 1 4.687 4.77 4.733 4.733 0 0 1-4.843 4.922 4.553 4.553 0 0 1-4.687-4.77Zm8.786-.027c0-2.809-1.8-4.689-4.045-4.689s-3.993 1.843-3.993 4.585c0 2.809 1.8 4.689 4.07 4.689 2.212 0 3.968-1.816 3.968-4.585Z"
					/>
					<path data-name="Path 238" d="m353.068 329.076-.206.5h-4.032v-9.39h.669v9.15h.31a21.584 21.584 0 0 0 3.235-.248Z" />
					<path
						data-name="Path 239"
						d="M361.737 320.186c-.1 1.03-.129 2.294-.129 4.1v5.45h-.079l-5.769-8.5v4.253c0 1.8.025 3.064.129 4.095h-.518c.1-1.03.129-2.291.129-4.095v-4.639l-.414-.6v-.04h.78l5.484 8.075v-3.991c0-1.8-.027-3.067-.129-4.1Z"
					/>
					<path
						data-name="Path 240"
						d="m368.589 324.013-.785 1.352v4.211h-.671v-4.186l-3.039-5.2h.773l2.809 4.91.7-1.2a24.192 24.192 0 0 0 1.88-3.716h.58q-1.145 1.914-2.247 3.829Z"
					/>
					<path
						data-name="Path 241"
						d="M372.054 324.927a4.627 4.627 0 0 1 4.706-4.895 4.411 4.411 0 0 1 3.53 1.521l-.438.386a3.775 3.775 0 0 0-3.168-1.7c-2.2 0-3.891 1.831-3.891 4.483 0 2.757 1.766 4.612 4.045 4.612a3.983 3.983 0 0 0 3.426-1.947l.156.092a4.489 4.489 0 0 1-8.365-2.551Z"
					/>
					<path data-name="Path 242" d="M389.467 320.186v9.378h-.656v-4.758h-5.269v4.766h-.675v-9.386h.669v4.372h5.276v-4.372Z" />
					<path
						data-name="Path 243"
						d="m337.901 344.426-.812-8.707-3.3 8.115h-.052l-3.49-7.909-.411 4.379c-.168 1.8-.233 3.091-.233 4.122h-.513c.181-1.03.322-2.331.5-4.134l.451-4.857-.156-.347v-.04h.721l3.389 7.679 3.119-7.679h.6l.877 9.378Z"
					/>
					<path
						data-name="Path 244"
						d="m345.823 343.938-.206.5h-4.032v-4.142l-.708.411-.089-.154.8-.463v-5.042h.659v4.664l2.477-1.417.089.154-2.566 1.466v4.283h.31a21.534 21.534 0 0 0 3.22-.248Z"
					/>
					<path
						data-name="Path 245"
						d="m350.794 338.874-.785 1.352v4.211h-.671v-4.186l-3.039-5.2h.773l2.809 4.909.7-1.2a24.193 24.193 0 0 0 1.88-3.716h.58q-1.137 1.918-2.247 3.83Z"
					/>
					<path
						data-name="Path 246"
						d="M361.767 335.048c-.1 1.03-.126 2.294-.126 4.1v5.45h-.077l-5.771-8.516v4.253c0 1.8.025 3.064.129 4.094h-.515c.1-1.03.129-2.291.129-4.094v-4.627l-.412-.62v-.04h.785l5.474 8.088v-3.991c0-1.8-.027-3.067-.131-4.1Z"
					/>
					<path
						data-name="Path 247"
						d="M364.448 339.816a4.733 4.733 0 0 1 4.843-4.922 4.552 4.552 0 0 1 4.689 4.766 4.733 4.733 0 0 1-4.843 4.922 4.553 4.553 0 0 1-4.689-4.766Zm8.786-.027c0-2.809-1.806-4.689-4.045-4.689s-3.993 1.831-3.993 4.585c0 2.809 1.8 4.689 4.07 4.689 2.207-.004 3.968-1.816 3.968-4.586Zm-4.265-5.784 1.03-1.944h1.135l-2.073 2.009Z"
					/>
					<path
						data-name="Path 248"
						d="M387.544 335.048a254.936 254.936 0 0 0-1.392 4.109l-1.8 5.425h-.052l-2.7-8.174-2.742 8.174h-.052l-3.172-9.534h.708l2.7 8.174 2.742-8.174h.052l2.698 8.15 1.377-4.122a25.421 25.421 0 0 0 1.07-4.033Z"
					/>
					<path
						data-name="Path 249"
						d="M354.851 357.126v7.332h-.5v-6.3a6.158 6.158 0 0 1-1.318.651v-.119a4.389 4.389 0 0 0 1.774-1.565Z"
					/>
					<path
						data-name="Path 250"
						d="M357.083 360.891c0-2.229 1.06-3.765 2.7-3.765 1.575 0 2.673 1.407 2.673 3.686 0 2.229-1.058 3.765-2.693 3.765-1.58 0-2.68-1.407-2.68-3.686Zm4.833 0c0-2.14-.78-3.624-2.177-3.624s-2.12 1.464-2.12 3.535c0 2.15.793 3.636 2.18 3.636s2.117-1.466 2.117-3.547Z"
					/>
					<path data-name="Rectangle 152" d="M348.146 292.839h19.569v.248h-19.569z" />
					<path data-name="Rectangle 153" d="M357.807 292.903h.248v17.082h-.248z" />
					<path
						data-name="Path 251"
						d="M367.838 310.05h-19.816v-17.221a9.908 9.908 0 1 1 19.816 0Zm-19.569-.248h19.323v-16.973a9.66 9.66 0 1 0-19.321 0Z"
					/>
					<path
						data-name="Path 252"
						d="M365.387 307.568h-14.912v-14.119a7.456 7.456 0 0 1 14.912 0Zm-14.664-.248h14.416v-13.871a7.208 7.208 0 1 0-14.416 0Z"
					/>
					<path
						data-name="Path 253"
						d="M362.939 305.14h-10.02v-11.083a5.011 5.011 0 0 1 10.02 0Zm-9.772-.248h9.524v-10.835a4.763 4.763 0 0 0-9.524 0Z"
					/>
				</g>
				<g data-name="Ellipse 71" transform="translate(198 163.504)" fill="none" stroke="#af875c">
					<circle cx={160} cy={160} r={160} stroke="none" />
					<circle cx={160} cy={160} r={159.5} />
				</g>
				<g data-name="Ellipse 72" transform="translate(153 118.504)" fill="none" stroke="#af875c">
					<circle cx={205} cy={205} r={205} stroke="none" />
					<circle cx={205} cy={205} r={204.5} />
				</g>
				<g data-name="Ellipse 73" transform="translate(108 73.504)" fill="none" stroke="#af875c">
					<circle cx={250} cy={250} r={250} stroke="none" />
					<circle cx={250} cy={250} r={249.5} />
				</g>
				<path data-name="Line 109" fill="none" stroke="#af875c" strokeDasharray={3} d="M84.772 323.972H34.21" />
				<path data-name="Line 112" fill="none" stroke="#af875c" strokeDasharray={3} d="M681.216 323.972h-50.562" />
				<path data-name="Line 110" fill="none" stroke="#af875c" strokeDasharray={3} d="M358.182 50.562V0" />
				<path data-name="Line 111" fill="none" stroke="#af875c" strokeDasharray={3} d="M358.182 647.007v-50.562" />
				<path data-name="Line 113" fill="none" stroke="#af875c" strokeDasharray={3} d="M164.384 130.837 128.63 95.084" />
				<path data-name="Line 114" fill="none" stroke="#af875c" strokeDasharray={3} d="m586.134 552.586-35.753-35.753" />
				<path data-name="Line 115" fill="none" stroke="#af875c" strokeDasharray={3} d="m551.043 130.837 35.753-35.753" />
				<path data-name="Line 116" fill="none" stroke="#af875c" strokeDasharray={3} d="m129.293 552.586 35.753-35.753" />
				<text transform="translate(357 -20)" fill="#af875c" fontSize={22} fontFamily="IvyMode-Regular, IvyMode">
					<tspan x={-7.183} y={0}>
						N
					</tspan>
				</text>
				<text transform="translate(11 331.504)" fill="#af875c" fontSize={22} fontFamily="IvyMode-Regular, IvyMode">
					<tspan x={-10.263} y={0}>
						W
					</tspan>
				</text>
				<text transform="translate(357 678.504)" fill="#af875c" fontSize={20} fontFamily="IvyMode-Regular, IvyMode">
					<tspan x={-5.4} y={0}>
						S
					</tspan>
				</text>
				<text transform="translate(694 331.504)" fill="#af875c" fontSize={22} fontFamily="IvyMode-Regular, IvyMode">
					<tspan x={0} y={0}>
						E
					</tspan>
				</text>
				<g
					data-name="Ellipse 172"
					onMouseOver={(e) =>
						cursorOn(e.target, 'park-krakowski', 452, 318.504, props.legend?.[0]?.name || '', props.legend?.[0]?.distance || '')
					}
					onMouseOut={(e) => cursorOff(e.target, 'park-krakowski')}
					transform="translate(452 318.504)"
					fill="#1f302d"
					stroke="#af875c"
				>
					<ellipse cx={18} cy={17.5} rx={18} ry={17.5} stroke="none" />
					<ellipse cx={18} cy={17.5} rx={17.5} ry={17} fill="none" />
				</g>
				<g
					id="go-back"
					data-name="Ellipse 171"
					onMouseOver={(e) =>
						cursorOn(e.target, 'park-jordana', 239, 356.504, props.legend?.[1]?.name || '', props.legend?.[1]?.distance || '')
					}
					onMouseOut={(e) => cursorOff(e.target, 'park-jordana')}
					transform="translate(239 354.504)"
					fill="#1f302d"
					stroke="#af875c"
				>
					<ellipse cx={18} cy={17.5} rx={18} ry={17.5} stroke="none" />
					<ellipse cx={18} cy={17.5} rx={17.5} ry={17} fill="none" />
				</g>
				<g
					data-name="Ellipse 173"
					onMouseOver={(e) =>
						cursorOn(e.target, 'wawel', 371, 552.504, props.legend?.[7]?.name || '', props.legend?.[7]?.distance || '')
					}
					onMouseOut={(e) => cursorOff(e.target, 'wawel')}
					transform="translate(371 552.504)"
					fill="#1f302d"
					stroke="#af875c"
				>
					<ellipse cx={18} cy={17.5} rx={18} ry={17.5} stroke="none" />
					<ellipse cx={18} cy={17.5} rx={17.5} ry={17} fill="none" />
				</g>
				<g
					data-name="Ellipse 174"
					onMouseOver={(e) =>
						cursorOn(e.target, 'barbakan', 575, 226.504, props.legend?.[6]?.name || '', props.legend?.[6]?.distance || '')
					}
					onMouseOut={(e) => cursorOff(e.target, 'barbakan')}
					transform="translate(575 226.504)"
					fill="#1f302d"
					stroke="#af875c"
				>
					<ellipse cx={18} cy={17.5} rx={18} ry={17.5} stroke="none" />
					<ellipse cx={18} cy={17.5} rx={17.5} ry={17} fill="none" />
				</g>
				<g
					data-name="Ellipse 77"
					onMouseOver={(e) =>
						cursorOn(
							e.target,
							'muzeum-narodowe',
							215,
							401.504,
							props.legend?.[2]?.name || '',
							props.legend?.[2]?.distance || '',
						)
					}
					onMouseOut={(e) => cursorOff(e.target, 'muzeum-narodowe')}
					transform="translate(215 401.504)"
					fill="#1f302d"
					stroke="#af875c"
				>
					<circle cx={18} cy={18} r={18} stroke="none" />
					<circle cx={18} cy={18} r={17.5} fill="none" />
				</g>

				<g
					data-name="Ellipse 79"
					onMouseOver={(e) =>
						cursorOn(
							e.target,
							'kosciol-mariacki',
							539,
							452.504,
							props.legend?.[5]?.name || '',
							props.legend?.[5]?.distance || '',
						)
					}
					onMouseOut={(e) => cursorOff(e.target, 'kosciol-mariacki')}
					transform="translate(539 452.504)"
					fill="#1f302d"
					stroke="#af875c"
				>
					<circle cx={18} cy={18} r={18} stroke="none" />
					<circle cx={18} cy={18} r={17.5} fill="none" />
				</g>
				<text
					id="kosciol-mariacki"
					data-name={6}
					transform="translate(557 476.504)"
					fill="#af875c"
					fontSize={19}
					fontFamily="SofiaPro-Light, Sofia Pro"
					fontWeight={300}
					pointerEvents="none"
				>
					<tspan x={-5.482} y={0}>
						6
					</tspan>
				</text>
				<g
					data-name="Ellipse 74"
					onMouseOver={(e) =>
						cursorOn(e.target, 'sukiennice', 482, 448.504, props.legend?.[4]?.name || '', props.legend?.[4]?.distance || '')
					}
					onMouseOut={(e) => cursorOff(e.target, 'sukiennice')}
					transform="translate(482 448.504)"
					fill="#1f302d"
					stroke="#af875c"
				>
					<ellipse cx={18} cy={17.5} rx={18} ry={17.5} stroke="none" />
					<ellipse cx={18} cy={17.5} rx={17.5} ry={17} fill="none" />
				</g>
				<text
					id="muzeum-narodowe"
					data-name={3}
					transform="translate(232 425.504)"
					fill="#af875c"
					fontSize={20}
					fontFamily="SofiaPro-Light, Sofia Pro"
					fontWeight={300}
					pointerEvents="none"
				>
					<tspan x={-5.3} y={0}>
						{props.legend?.[2]?.number || ''}
					</tspan>
				</text>
				<g
					data-name="Ellipse 78"
					onMouseOver={(e) =>
						cursorOn(e.target, 'blonia', 157, 396.504, props.legend?.[3]?.name || '', props.legend?.[3]?.distance || '')
					}
					onMouseOut={(e) => cursorOff(e.target, 'blonia')}
					transform="translate(157 396.504)"
					fill="#1f302d"
					stroke="#af875c"
				>
					<circle cx={17.5} cy={17.5} r={17.5} stroke="none" />
					<circle cx={17.5} cy={17.5} r={17} fill="none" />
				</g>

				<text
					id="blonia"
					data-name={4}
					transform="translate(174 419.504)"
					fill="#af875c"
					fontSize={19}
					fontFamily="SofiaPro-Light, Sofia Pro"
					fontWeight={300}
					pointerEvents="none"
				>
					<tspan x={-5.339} y={0}>
						{props.legend?.[3]?.number || ''}
					</tspan>
				</text>
				<text
					id="sukiennice"
					data-name={5}
					transform="translate(499 472.504)"
					fill="#af875c"
					fontSize={20}
					fontFamily="SofiaPro-Light, Sofia Pro"
					fontWeight={300}
					pointerEvents="none"
				>
					<tspan x={-5.48} y={0}>
						{props.legend?.[4]?.number || ''}
					</tspan>
				</text>
				<text
					id="park-jordana"
					data-name={2}
					transform="translate(256 378.504)"
					fill="#af875c"
					fontSize={20}
					fontFamily="SofiaPro-Light, Sofia Pro"
					fontWeight={300}
					pointerEvents="none"
				>
					<tspan x={-5.05} y={0}>
						{props.legend?.[1]?.number || ''}
					</tspan>
				</text>
				<text
					id="park-krakowski"
					data-name={1}
					transform="translate(469 342.504)"
					fill="#af875c"
					fontSize={20}
					fontFamily="SofiaPro-Light, Sofia Pro"
					fontWeight={300}
					pointerEvents="none"
				>
					<tspan x={-3.35} y={0}>
						{props.legend?.[0]?.number || ''}
					</tspan>
				</text>
				<text
					id="wawel"
					data-name={8}
					transform="translate(388 576.504)"
					fill="#af875c"
					fontSize={20}
					fontFamily="SofiaPro-Light, Sofia Pro"
					fontWeight={300}
					pointerEvents="none"
				>
					<tspan x={-4.92} y={0}>
						{props.legend?.[7]?.number || ''}
					</tspan>
				</text>
				<text
					id="barbakan"
					data-name={7}
					transform="translate(592 250.504)"
					fill="#af875c"
					fontSize={20}
					fontFamily="SofiaPro-Light, Sofia Pro"
					fontWeight={300}
					pointerEvents="none"
				>
					<tspan x={-3.5} y={0}>
						{props.legend?.[6]?.number || ''}
					</tspan>
				</text>
			</g>
		</svg>
	);
};

export default SolarSystemV3;
